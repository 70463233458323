
import { useNavigate } from "react-router"
import manSelfieWithJeep from '../assets/manSelfieWithJeep.jpeg'
import groupPhoto from '../assets/groupPhoto.jpeg'
import package3 from '../assets/package3.jpg'
import { usePackage } from "../context/packageProvider"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"



export default function Package({openbook}){
   let navigate  = useNavigate();
   let {t} = useTranslation();
   let {changePackage} = usePackage(); 


function handleClick(){
    changePackage('1 night 2 days')
    openbook()
}


    return(
        <div id = 'package' className="package_container" >
           <h2>{t('packages.title')}</h2>
           <div style={{'margin':'auto','width':'5rem'}} className='horizontal-line' ></div>
           <div className="package_details" >
            <div className="package_img">
            <h3>1. {t('packages.p1.title')}</h3>
            <br/>
            <img src = {manSelfieWithJeep} alt = "image" loading="lazy"/>
            <table>
        <tr>
            <th style={{"padding" : "0px 20px"}} > Day </th>
            <th>Activities</th>
        </tr>
        <tr>
            <td rowspan="5">Day 1</td>
            <td>[12:30] Travelling to Chitwan. Free pickup from bus stop and paid pickup from airport.</td>
        </tr>
        <tr>
            <td>[13:00] Lunch</td>
        </tr>
        <tr>
            <td>[14:30] Trip discussion at T.J.C (Complementary service)</td>
        </tr>
        <tr>
            <td>[15:00] 2-hour Jeep Safari in the Buffer zone (combined). <br/> <small>Note: Private safari can be arranged.</small></td>
        </tr>
        <tr>
            <td>[20:00] Tharu Cultural Dance presentation with local villagers.</td>
        </tr>
        <tr>
            <td rowspan="3">Day 2</td>
            <td>[06:00] Wakeup call</td>
        </tr>
        <tr>
            <td>[06:30] Breakfast</td>
        </tr>
        <tr>
            <td>[07:15] Departure for onward journey</td>
        </tr>
    </table>

            <div>
                <button className='package_book_btn' style={{"float" : "center"}}  onClick={()=>{
                    changePackage('1 night 2 days')
                    openbook()
                    }}  >Book now</button>
             </div>
            </div>
           </div>

           <div className="package_details"  >

            <div className="package_img">
            <h3>2. {t('packages.p2.title')}</h3>
            <br/>
            <img src = {groupPhoto} alt = "image" loading="lazy"/>
            <table>
        <tr>
            <th style={{"padding" : "0px 20px"}} >Day</th>
            <th>Activities</th>
        </tr>
        <tr>
            <td rowspan="6">Day 1</td>
            <td>[13:00] Travelling to Chitwan. Free pickup from bus stop and paid pickup from airport.</td>
        </tr>
        <tr>
            <td>[14:00] Lunch at the hotel restaurant.</td>
        </tr>
        <tr>
            <td>[15:00] Trip discussion at T.J.C (Complementary service)</td>
        </tr>
        <tr>
            <td>[16:00] Short sight-seeing around the park area and village tour to nearby ethnic Tharu village.</td>
        </tr>
        <tr>
            <td>[20:00] Tharu Cultural Dance presentation with local villagers.</td>
        </tr>
        <tr>
            <td>[21:00] Dinner</td>
        </tr>
        <tr>
            <td rowspan="6">Day 2</td>
            <td>[06:00] Wakeup call</td>
        </tr>
        <tr>
            <td>[06:30] Breakfast</td>
        </tr>
        <tr>
            <td>[07:00 - 11:00] 45-minute canoe trip followed by a 3-hour jungle walk and elephant breeding center visit.</td>
        </tr>
        <tr>
            <td>[11:30] Lunch at the hotel.</td>
        </tr>
        <tr>
            <td>[13:00 - 17:00] Half-day Jeep safari inside the national park (combined). <br/> <small>Note: Private safari can be arranged.</small></td>
        </tr>
        <tr>
            <td>[20:00] Dinner</td>
        </tr>
        <tr>
            <td rowspan="3">Day 3</td>
            <td>[06:00] Wakeup call</td>
        </tr>
        <tr>
            <td>[06:30] Breakfast</td>
        </tr>
        <tr>
            <td>[07:15] Departure for onward journey</td>
        </tr>
    </table>

    <div>
                <button className='package_book_btn' style={{"float" : "center"}}  onClick={()=>{
                    changePackage('2 night 3 days')
                    openbook()
                    }}  >Book now</button>
             </div>
            </div>
           
           </div>

           <div className="package_details" >
            <div className="package_img">
            <h3>3. {t('packages.p3.title')}</h3>
                <br/>
            <img style={{widht: "10%"}} src = {package3} alt = "image" loading="lazy"/>
            <table>
        <tr>
            <th style={{"padding" : "0 20px"}} >Day</th>
            <th>Activities</th>
        </tr>
        <tr>
            <td rowspan="5">Day 1</td>
            <td>[13:00] Travelling to Chitwan. Free pickup from bus stop and paid pickup from the airport.</td>
        </tr>
        <tr>
            <td>[14:00] Lunch at the hotel restaurant.</td>
        </tr>
        <tr>
            <td>[14:30] Trip discussion at T.J.C (Complementary service).</td>
        </tr>
        <tr>
            <td>[16:00] Short sight-seeing around the park and village tour to nearby ethnic Tharu village.</td>
        </tr>
        <tr>
            <td>[21:00] Return to the hotel for dinner.</td>
        </tr>
        <tr>
            <td rowspan="5">Day 2</td>
            <td>[06:00] Wakeup call.</td>
        </tr>
        <tr>
            <td>[06:30] Breakfast.</td>
        </tr>
        <tr>
            <td>[07:00 - 11:00] Half Day Jungle Walk (Excellent for bird watching and seeing rare crocodile species).</td>
        </tr>
        <tr>
            <td>[11:30] Lunch at the hotel.</td>
        </tr>
        <tr>
            <td>[13:00 - 17:00] Half Day Jeep safari inside the national park. (Combined) <br/> <small>Note: Private safari can be arranged.</small></td>
        </tr>
        <tr>
            <td rowspan="6">Day 3</td>
            <td>[06:00] Wakeup call.</td>
        </tr>
        <tr>
            <td>[06:30] Breakfast.</td>
        </tr>
        <tr>
            <td>[07:00 - 11:00] 45-minute canoe trip followed by a visit to the elephant breeding center.</td>
        </tr>
        <tr>
            <td>[14:00] 2-hour Jeep safari in the buffer zone area.</td>
        </tr>
        <tr>
            <td>[20:00] Tharu Cultural Dance presentation with local villagers.</td>
        </tr>
        <tr>
            <td>[21:00] Dinner.</td>
        </tr>
        <tr>
            <td rowspan="3">Day 4</td>
            <td>[06:00] Wakeup call.</td>
        </tr>
        <tr>
            <td>[06:30] Breakfast.</td>
        </tr>
        <tr>
            <td>[07:00] Departure for onward journey.</td>
        </tr>
    </table>
    <div>
                <button className='package_book_btn' style={{"float" : "center"}}  onClick={()=>{
                    changePackage('3 night 4 days')
                    openbook()
                    }}  >Book now</button>
                    <br/>
                    <br/>
             </div>


            </div>
           </div>
         
           
        </div>
    )
}