
import { useState } from 'react';
import sample5 from '../assets/3.jpeg';
import video from '../assets/video.mp4'
import Bottom from '../components/bottom';
import CustomerCount from '../components/customerCount';
import Modal from '../components/modal';
import Reviews from '../components/reviews';
import { usePackage } from '../context/packageProvider';
import bg from '../assets/bg.png'
import room from '../assets/str2.jpeg'
import { Link, useNavigate } from 'react-router-dom';
import Offer from '../components/offer';
import GoogleMap from '../components/googlemap';
import { useTranslation } from 'react-i18next';
import Package from '../components/package';
import ReviewSlider from '../components/reviewSlider';
import GallerySection from '../components/gallerySection';
import room1 from '../assets/room1.jpg'
import room2 from '../assets/room2.JPG'
import OverView from '../components/overView';
import deluxecottage from '../assets/deluxe-cottage.jpeg'
import standarddeluxe from '../assets/standard-deluxe.jpeg'
import tower from '../assets/tower.jpeg'
import Activities from '../components/activities';
import Guide from '../components/guide';


export default function Home(){


    const {t} = useTranslation();
    console.log(t('header'));
    console.log(t('header .navigation.home'));
    const [active,setActive] = useState(false);
    let navigate = useNavigate();
    const {open,closeBook,openBook,rooms,adults, childrens,changeCheckin,changeCheckout}  = usePackage();
    const defaultDate = new Date();

    function handleClick(){
        setActive(!active);
    }
    function handleTripple() {

        navigate('/room/standard-tripple-room');
    }
    function handleDeluxe() {

        navigate('/room/deluxe-room')
    }
    function handleCottage() {
      
       navigate('/room/cottage-room')
    }

    return(
        <div className='home_page' >
            <main>
                <div className='background_img' ></div>
                <div className='home_container' >
                    <div className='home_book_container' >
                        <h1>{t('home.header.one')} <br/>
                        {t('home.header.second')}<br/>
                        {t('home.header.third')}
                         </h1>
                         <p className='second_header'>
                            {t('home.header.p-one')}<br/>
                            {t('home.header.p-second')}
                        </p>
                     
                        <br/>
                        <br/>

                        <div className='home_search_container'>

                       
                        <label style={{'fontWeight' : '400'}} >{t('home.book.checkin')}</label>
    
                      <input type="date" id="check-in" name="check-in" placeholder="Check-in"
                       defaultValue={defaultDate.toISOString().slice(0, 10)}
                       onChange = {(e)=>changeCheckin(e.target.value)}
                       />
                        <hr/> 
                     
                        <label style={{'fontWeight' : '400'}} >{t('home.book.checkout')}</label>
    
                      <input type="date" id="check-in" name="check-in" placeholder="Check-in"
                       defaultValue={defaultDate.toISOString().slice(0, 10)}
                       onChange = {(e)=>
                        changeCheckout(e.target.value)
    
                    }
                       />
                     
                     
                        
                     <button className='book_btn' onClick = {()=>openBook()} >{t('home.book.button1')}</button>
                        </div>
                    </div>
                    <div className='home_image' >
                        <img src = {bg} alt = 'bg' loading='lazy' rel='bg'/>

                    </div>

                </div>
                <OverView/>

                <div id = 'room' className='our_room_container' >
                    <div>
                        <h2>{t('room.title')}</h2>
                        
                        <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
                    </div>

                    <div className='room_cards'>
                        <div className='room_card' onClick={handleTripple} >
                            <div to='/room/standard-tripple-room' className='room_card_img'>
                                <img src = {standarddeluxe} loading = 'lazy' alt = 'room image'/>
                            </div>
                           
                            <div style={{'padding':'20px'}} >
                            <p className='room_title' >{t('room.room1.title')}</p>
                            
                            <p> $20.00 / {t('room.pernight')}</p>
                            <s>$30.00</s>
                            </div>
                        </div>
                        <div className='room_card' onClick={handleCottage} >
                            <div className='room_card_img'>
                                <img src = {deluxecottage} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'20px'}} >
                            <p className='room_title' >{t('room.room3.title')}</p>
                            <p>$24.00 / {t('room.pernight')}</p>
                            <s>$35.00</s>
                            </div>
                        </div>
                        <div className='room_card' onClick={handleDeluxe} >
                            <div className='room_card_img' >
                                <img src = {tower} loading = 'lazy' alt = 'room image'/>
                            </div>
                          
                            <div style={{'padding':'20px'}} >
                            <p className='room_title' >{t('room.room2.title')}</p>
                            
                            <p>$50.00 / per person</p>
                            </div>

                        </div>


                    </div>

                </div>

                 <Guide t = {t}/>
                <Package t = {t} openbook = {()=>openBook()} />
            <Activities t = {t} />
                
                {/* <Offer t = {t}/> */}
                {/* <div className='activites_div' >
                      <h2>{t('home.activities.title')}</h2>
                      <div className='activities'>
                        <div >
                            <p className='jeep_safari_p'>{t('home.activities.one')}</p>
                            <div className='jeep_safari'>

                            </div>

                        </div>

                        <div >
                            <p className='jungle_walk_p'>{t('home.activities.second')}</p>
                            <div className='jungle_walk'>

                            </div>

                        </div>

                        <div style={{'width' : 'fitContent'}} >
                            <p className='photograph_p' >
                            {t('home.activities.third')}</p>
                            <div className='photograph'>

                            </div>

                        </div>
                      </div>
                </div>
    */}
    <div className='services_container'>
        <h2> UNWIND SERVICES</h2>
        <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>

        <div className='service_div' >
            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/70/external-free-wifi-digital-nomad-flaticons-lineal-color-flat-icons.png" alt="external-free-wifi-digital-nomad-flaticons-lineal-color-flat-icons"/>
            <p style={{fontSize:"20px"}} >Free Wifi</p>
            </div>

            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/color/70/waiter.png" alt="waiter"/>
            <p style={{fontSize:"20px"}} >Restaurant & Bar</p>
            </div>

            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/fluency/70/ticket-purchase.png" alt="ticket-purchase"/>
            <p style={{fontSize:"20px"}} >Bus & Flight Ticket</p>
            </div>

            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/external-vitaliy-gorbachev-lineal-color-vitaly-gorbachev/70/external-garden-landscape-vitaliy-gorbachev-lineal-color-vitaly-gorbachev.png" alt="external-garden-landscape-vitaliy-gorbachev-lineal-color-vitaly-gorbachev"/>
            <p style={{fontSize:"20px"}} >Lust Garden</p>
            </div>

            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/external-wanicon-lineal-color-wanicon/70/external-charging-car-service-wanicon-lineal-color-wanicon.png" alt="external-charging-car-service-wanicon-lineal-color-wanicon"/>
            <p style={{fontSize:"20px"}} >EV-charging</p>
            </div>

            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/external-flaticons-flat-flat-icons/70/external-wheel-chair-inhome-service-flaticons-flat-flat-icons.png" alt="external-wheel-chair-inhome-service-flaticons-flat-flat-icons"/>
            <p style={{fontSize:"20px"}} >Wheel chair accessible</p>
            </div>

            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/pulsar-color/70/outdoor-parking.png" alt="outdoor-parking"/>
            <p style={{fontSize:"20px"}} >Free Parking</p>
            </div>
            <div className='service' >
            <img width="70" height="70" src="https://img.icons8.com/color/70/why-us-male--v1.png" alt="why-us-male--v1"/>
            <p style={{fontSize:"20px"}} >Help & Support</p>
            </div>
           
           
     </div>

            

    </div>
                <Reviews t = {t} openbook = {()=>openBook()}/>
                <ReviewSlider />
                <GallerySection t = {t}/>
                
               <GoogleMap t = {t}/>
               
               
                <Bottom/>

                
            </main>
            <Modal  open = {open} close = {()=>closeBook()}/>
            
        </div>
    )
}



{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.474239102442!2d84.49181477561467!3d27.578823131148102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3994ee40673e5009%3A0x8b4ee085418fdfeb!2sTravellers%20Jungle%20Camp!5e0!3m2!1sen!2snp!4v1682649668384!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}



                {/* <div className="heroSection">
                    <div className="search" >

                   <div class="search-container">

                    <label style={{'fontWeight' : '400'}} >Check in</label>
                      <input type="date" id="check-in" name="check-in" placeholder="Check-in"
                       defaultValue={defaultDate.toISOString().slice(0, 10)}/> <hr/> 
                      <label style={{'fontWeight' : '400'}}>Check out</label>  
                       <input type="date" id="check-out" name="check-out" placeholder="Check-out"
                        defaultValue={defaultDate.toISOString().slice(0, 10)}/>
                        <hr/>
                        <div className='change_div'   >
                        {
                        active&& 
                        <CustomerCount changeActive = {()=>setActive(false)}/>
                        

                    }
                        <svg onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                           <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                        </svg>&nbsp;
                        {adults} adult - {childrens} childrens - {rooms} room&nbsp;
                            <svg onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-bar-up" viewBox="0 0 16 16">
                                 <path fill-rule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4z"/>
                           </svg></div>
                     <button className='book_btn' onClick = {()=>setOpen(true)} >Book a stay</button>
                  </div>
                  </div>
                </div> */}