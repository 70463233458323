

import '../css/gallery.css';

import img2 from "../assets/gallery/2.jpg"
import img3 from "../assets/gallery/3.jpg"

import img5 from "../assets/gallery/5.jpeg"
import img6 from "../assets/gallery/6.jpg"
import img7 from "../assets/gallery/7.jpeg"
import img8 from "../assets/gallery/8.jpg"
import img9 from "../assets/gallery/9.jpg"
import img11 from "../assets/gallery/11.jpg"
import img12 from "../assets/gallery/12.jpg"
import img13 from "../assets/gallery/13.jpg"
import img14 from "../assets/gallery/14.jpg"



export default function Gallery(){

    const images= [
 
        {url : img2},
        {url : img3},
        {url : img5},
        {url : img6},
        {url : img7},
        {url : img8},
        {url : img9},
        {url : img11},
        {url : img12},
        {url : img13},
        {url : img14},

  
    ]

    return(
        <div id = "gallery" >
   
            <h2>Glances of <span style = {{color:"green"}} >Sauraha</span></h2>
            <div style={{'margin':'auto','width':'10rem'}} className='horizontal-line' ></div>

            <br/>
           <div className='container'>
            {
                images.map((img,index)=>{
                    console.log(index)
                    return(
                        <div>
                            <img src = {img.url} loading = 'lazy' alt = "chitwan" />
                        </div>
                    )


                })
            }


           </div>
        
         <p>Copyright © 2023 Travellers Jungle Camp. All rights reserved<br/>

Made by <a target= '_blank' href = 'https://chetanpun.com.np/' >Chetan</a></p>
        </div>
    )

}